  jQuery(document).ready(function(){
                                
        $(function() {
        $(".plan-switch__item--active1").click(function() {
        $('#plan-card').addClass("active").ready(function() {
        	$('.plan-card__btn').addClass('blue');
        	$('.plan-card__btn--inactive').removeClass('blue')
        });
         $('#plan-card-inactive').removeClass("active");
            });
        });
        $(function() {
        $("#company").click(function() {
        $('#plan-card-inactive').addClass("active").ready(function() {
        	$('.plan-card__btn--inactive').addClass('blue');
        	$('.120').removeClass('blue')
        });
         $('#plan-card').removeClass("active");
            });
        });
        $(function() {
        $('#100').click(function() {
   		$(this).addClass('plans');
   		 $('#company').removeClass("plans");
       
            });
		});
		$(function() {
        $('#company').click(function() {
   		$(this).addClass('plans');
   		 $('#100').removeClass("plans");
       
            });
		});

$('.continue').prop('disabled', true);
$('.agree').change(function() {
$('.continue').prop('disabled', function(i, val) {
return !val;
}) }); 







$(".formsubmit2").submit(function() { return false; });

$(".formsubmit2").submit(function () { 
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
      $.fancybox.open({
        src  : '#success',
        type : 'inline',
        opts : {
          onComplete : function() {
            console.info('done!');
          },
          afterClose: function(){
      $('.continue').prop('disabled', true);
    }
        }
});
        });
        return false;
    });

$(".formsubmit1").submit(function() { return false; });

$(".formsubmit1").submit(function () { 
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            // код после успешной отправки формы
      $.fancybox.open({
        src  : '#success2',
        type : 'inline',
        opts : {
          onComplete : function() {
            console.info('done!');
          },
          afterClose: function(){
      $('.continue').prop('disabled', true);
    }
        }
});
        });
        return false;
    });


$('.btn_download').click(function() {
   if ($('body').hasClass('fancybox-active')) {
    $('.continue').prop('disabled', true);
   } else {
    $('.continue').prop('disabled', true);
   }
});

$('[data-fancybox]').click(function() {
   if ($('body').hasClass('fancybox-active')) {
    $('.continue').prop('disabled', true);
   } else {
    $('.continue').prop('disabled', true);
   }
});



            // Плавный скролл по якорям
           $('a[href^="#"]').click(function () { 
     elementClick = $(this).attr("href");
    destination = $(elementClick).offset().top;
    {
    $('html,body').animate( { scrollTop: destination }, 1000);
    } 
    return false;﻿
        });
           });

//   $(function() {
// $("[data-fancybox]").fancybox();
// });
 
$(window).scroll(function(){
if ($(this).scrollTop() > 100) {
$('.scrollup').fadeIn();
} else {
$('.scrollup').fadeOut();
}
});
 
$('.scrollup').click(function(){
$("html, body").animate({ scrollTop: 0 }, 600);
return false;
});
 


$('.head_menu_btn').click(function() { //выбираем класс icon-menu и добавляем метод click с функцией, вызываемой при клике
  $('.menuadaptiv').show();
});
$('.menuadaptiv_close').click(function() { //выбираем класс icon-menu и добавляем метод click с функцией, вызываемой при клике
  $('.menuadaptiv').hide();
});


$('a[href^="#"], a[href^="."]').click( function(){ // если в href начинается с # или ., то ловим клик
      var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href
        if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
      $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); // анимируем скроолинг к элементу scroll_el
      $('.menuadaptiv').hide();
      }
      return false; // выключаем стандартное действие
    });




$("#check_medium").prop('checked' , true);

var check = 0; 
var guest = 0; 
var renta = 0;
var mincalc = 1090000;
var medcalc = 2190000; 
var maxcalc = 3250000; 
var result = 0;


calc();
function calc() {
check = $('#check_calc').val()*1; 
guest = $('#guest_calc').val()*1; 
renta = $('#renta_calc').val()*1; 

result = ((check* guest)- renta);

if ( $("#check_min:checked").length != 0 ) {
    if (result <= mincalc) {
        $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else if (result > mincalc) {
         $("#calc_itogs").text( mincalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else {
        return false;
      }
} else if ( $("#check_medium:checked").length != 0 ) {
    if (result <= medcalc) {
        $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else if (result > medcalc) {
         $("#calc_itogs").text( medcalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else {
        return false;
      }
} else if ( $("#check_max:checked").length != 0 ) {
    if (result <= maxcalc) {
        $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else if (result > maxcalc) {
         $("#calc_itogs").text( maxcalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
      } else {
        return false;
      }
} else {
 return false;
}
};

$('#check_medium').click( function(){ 
    $("#check_min").prop('checked', false);
    $("#check_max").prop('checked', false);
    calc();
});
$('#check_min').click( function(){ 
    $("#check_medium").prop('checked', false);
    $("#check_max").prop('checked', false);
    calc();
});
$('#check_max').click( function(){ 
    $("#check_medium").prop('checked', false);
    $("#check_min").prop('checked', false);
    calc();
});

$("#check_calc").keyup( function() {
  calc();
});
$("#guest_calc").keyup( function() {
  calc();
});
$("#renta_calc").keyup( function() {
  calc();
});



// $("input [type=number]").change(function() {
// check = $('#check_calc').val(); 
// guest = $('#guest_calc').val(); 
// renta = $('#renta_calc').val(); 

// result = ((check* guest)- renta);

// if( $("#check_min:checked").length != 0 ) {
//     if (result <= mincalc) {
//         $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       } else if (result > mincalc) {
//          $("#calc_itogs").text( mincalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       }
// }
// else {
//  $("#calc_itogs").text(mincalc);
// }

// if( $("#check_medium:checked").length != 0 ) {
//     if (result <= medcalc) {
//         $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       } else if (result > medcalc) {
//          $("#calc_itogs").text( medcalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       }
// }
// else {
//  $("#calc_itogs").text(medcalc);
// }

// if( $("#check_max:checked").length != 0 ) {
//     if (result <= maxcalc) {
//         $("#calc_itogs").text( result.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       } else if (result > maxcalc) {
//          $("#calc_itogs").text( maxcalc.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1 ") );
//       }
// }
// else {
//  $("#calc_itogs").text(maxcalc);
// }

// });

 

